import React, { useContext, useEffect } from "react";
import "../../Styles/FullTimeModalStyles/FullTimeModalStyles.css";
import { AppContext } from "../../Context/AppContext/AppContext";
import { COLORS, MARGINS } from "../../Styles/GlobalStyles";

interface Props {
  id: string;
  lineName: string;
  arrayOfTimes: string[];
}

const FullTimeScheduledModal: React.FC<Props> = ({
  id,
  lineName,
  arrayOfTimes,
}) => {
  const { closeScheduledTimeModal } = useContext(AppContext);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const handleOverlayClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (
      (event.target as HTMLElement).classList.contains("FullTimeModalOverlay")
    ) {
      closeScheduledTimeModal();
    }
  };

  return (
    <div
      className="FullTimeModalOverlay"
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        handleOverlayClick(event);
      }}>
      <div>
        <div
          className="FullTimeModalContent"
          style={{ backgroundColor: COLORS.mossGreen }}>
          <button
            className="FullTimeModalCloseButton"
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              closeScheduledTimeModal();
            }}>
            <img
              src="/images/img/xMark.png"
              alt=""
              style={{ width: 20, height: 20 }}
            />
          </button>
          <div className="FullTimeModalModalBody">
            <h3 style={{ color: "white", marginBottom: MARGINS.margin15 }}>
              Целосен возен ред за Линија {lineName}
            </h3>
            {arrayOfTimes && (
              <div className="FullTimeModalTimeGrid">
                {arrayOfTimes.map((time, index) => {
                  return (
                    <div
                      key={`${time}-${index}`}
                      className="FullTimeModalTimeBox"
                      style={{
                        backgroundColor: COLORS.cardBackgroundColorLight,
                      }}>
                      {time}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullTimeScheduledModal;
