import React from "react";
import "../../Styles/LoadingPageStyle/LoadingPageStyle.css";
import { COLORS } from "../../Styles/GlobalStyles";

const LoadingPage = () => {
  return (
    <div className="loading-page">
      <div className="spinner" style={{ borderTopColor: COLORS.mossGreen }} />
    </div>
  );
};

export default LoadingPage;
