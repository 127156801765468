import React, { useEffect, useRef } from "react";
import { AllStops, TimeToNextStop } from "../../Types/Types";
import {
  findClosestTimes,
  formatTime,
  getMinutesFromMidnight,
  scrollToTop,
} from "../../Utils/UtilityFunctions";
import "../../Styles/TimelineStyle/TimelineStyle.css";
import { COLORS } from "../../Styles/GlobalStyles";
import { useNavigate } from "react-router-dom";

interface TimelineProps {
  data?: AllStops[];
  nextStop?: {
    id: string;
    name: string;
  };
  timeToNextStop?: TimeToNextStop[] | null;
  id: string;
}

const Timeline: React.FC<TimelineProps> = ({
  data,
  nextStop,
  timeToNextStop,
}) => {
  const navigate = useNavigate();
  const nextStopRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (nextStopRef.current) {
      nextStopRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [nextStop]);

  const handleNavigate = (id: string) => {
    navigate(`/stop/${id}`);
    scrollToTop();
  };

  const sortedData = data
    ?.map((item, index) => {
      const isNextStop = item.id === nextStop?.id;
      const isPassed = nextStop
        ? index < data.findIndex((stop) => stop.id === nextStop.id)
        : false;
      return { ...item, isPassed, isNextStop };
    })
    .sort((a, b) => Number(a.isPassed) - Number(b.isPassed));

  return (
    <div className="timeline-container">
      {sortedData?.map((item, index) => {
        const lastItem = index === sortedData.length - 1;

        const currentMinutes = getMinutesFromMidnight(
          new Date().toTimeString().slice(0, 5)
        );

        const { closestTime, nextTimes } = findClosestTimes(
          currentMinutes,
          item.scheduledTime || []
        );

        const matchingTimeToNextStop = timeToNextStop?.find(
          (timeItem) => timeItem.toStop === item.name
        );

        const activeTime = matchingTimeToNextStop
          ? formatTime(matchingTimeToNextStop.totalTime)
          : closestTime;

        return (
          <div
            key={`${item.name}-${index}`}
            className={`item-container ${item.isPassed ? "inactive-stop" : ""}`}
            ref={item.isNextStop ? nextStopRef : null}>
            <div className="timeline-connector">
              <div
                className="circle"
                style={{
                  backgroundColor: item.isNextStop ? COLORS.mossGreen : "black",
                }}
              />
              {!lastItem && <div className="line short-line" />}
            </div>

            <div
              className={`item-content ${item.isPassed ? "passed-stop" : ""}`}
              style={{
                backgroundColor: item.isNextStop
                  ? COLORS.mossGreen
                  : COLORS.cardBackgroundColorLight,
              }}
              onClick={(
                event: React.MouseEvent<HTMLDivElement, MouseEvent>
              ) => {
                event.preventDefault();
                handleNavigate(item.id);
              }}>
              <div className="text-container">
                {item.isNextStop ? (
                  <img
                    className="imgTimelineItem"
                    src="/images/img/busLinesAndBusStopDetailsLineLight.png"
                    alt=""
                  />
                ) : (
                  <img
                    className="imgTimelineItem"
                    src="/images/img/busLinesAndBusStopDetailsLine.png"
                    alt=""
                  />
                )}

                <div className="text-container-inner">
                  <h4
                    className="item-title"
                    style={{ color: item.isNextStop ? "white" : "black" }}>
                    {item.name}
                  </h4>
                  {nextTimes.length > 0 ? (
                    <div className="next-times">
                      {matchingTimeToNextStop && (
                        <div
                          className="time-container"
                          style={{
                            backgroundColor: item.isNextStop
                              ? COLORS.cardBackgroundColorLight
                              : COLORS.mossGreen,
                          }}>
                          <span
                            className="timelineTimeLeft"
                            style={{
                              color: item.isNextStop
                                ? COLORS.mossGreen
                                : "white",
                              fontWeight: item.isNextStop ? "bold" : "",
                            }}>
                            {activeTime || ""}
                          </span>
                        </div>
                      )}

                      {matchingTimeToNextStop
                        ? nextTimes.slice(0, 3).map((time, idx) => (
                            <div className="time-box" key={idx}>
                              <p className="m-0">{time}</p>
                            </div>
                          ))
                        : nextTimes.slice(0, 4).map((time, idx) => (
                            <div className="time-box" key={idx}>
                              <p className="m-0">{time}</p>
                            </div>
                          ))}

                      {}
                    </div>
                  ) : (
                    <span>Моментално нема автобус.</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
