import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Context/AppContext/AppContext";
import BusStopCard from "../../Components/BusStopCard/BusStopCard";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import Container from "../../Components/Container/Container";
import { COLORS } from "../../Styles/GlobalStyles";
import { LocationContext } from "../../Context/LocationContext/LocationContext";
import FormComponent from "../../Components/FormComponent/FormComponent";
import "../../Styles/BusStopsPageStyles/BusStopsPageStyles.css";

const BusStopsPage = () => {
  const { busStops, selectValue, searchValue } = useContext(AppContext);
  const [showScrollTopButton, setShowScrollTopButton] =
    useState<boolean>(false);
  const [showAllBusStops, setShowAllBusStops] = useState<boolean>(false);
  const { location } = useContext(LocationContext);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const viewportHeight = window.innerHeight;

      setShowScrollTopButton(scrollPosition > 1.5 * viewportHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const busStopsToShow =
    !selectValue && !searchValue && !showAllBusStops
      ? busStops.slice(0, 6)
      : busStops;

  if (!busStops || busStops.length === 0) {
    return (
      <div style={{ marginTop: "90px" }}>
        <Container>
          <FormComponent />
        </Container>
        <LoadingPage />
      </div>
    );
  }

  return (
    <div style={{ marginTop: "90px" }}>
      <Container>
        <FormComponent />
      </Container>
      <Container>
        {busStopsToShow.map((item, index) => (
          <BusStopCard key={`${item.id}-${index}`} {...item} />
        ))}
      </Container>

      {!showAllBusStops &&
        busStops.length > 6 &&
        !searchValue &&
        !selectValue && (
          <button
            onClick={() => setShowAllBusStops(true)}
            className="showMoreButton"
            style={{
              backgroundColor: COLORS.mossGreen,
            }}>
            Прикажи повеќе
          </button>
        )}

      {showScrollTopButton && (
        <button
          className="scrollToTopButton"
          onClick={scrollToTop}
          style={{ backgroundColor: COLORS.mossGreen }}>
          ↑
        </button>
      )}
    </div>
  );
};

export default BusStopsPage;
