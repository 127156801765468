import React from "react";
import { HomePage2ActiveBus } from "../../Types/Types";
import { formatTime, scrollToTop } from "../../Utils/UtilityFunctions";
import "../../Styles/ActiveLineCardStyle/ActiveLineCardStyle.css";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../Styles/GlobalStyles";

const ActiveLineCard: React.FC<HomePage2ActiveBus> = (item) => {
  const navigate = useNavigate();

  const navigateTo = (id: string) => {
    navigate(`/line/${id}`);
    scrollToTop();
  };

  return (
    <div
      className="activeBusLineContainer"
      style={{ borderColor: COLORS.mossGreen }}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        navigateTo(item.id);
      }}>
      <div className="mr-2">
        <img
          src="./images/img/busLinesAndBusStopDetailsLine.png"
          alt=""
          className="activeLineCardImage"
        />
      </div>

      <div>
        <div className="d-flex align-items-center mb-2">
          <h4 className="activeBusCardTitleText m-0">Линија {item.line}</h4>
          <img
            src="./images/img/voZivo.png"
            alt=""
            className="blinkingImageActiveBus"
            onClick={(
              event: React.MouseEvent<HTMLImageElement, MouseEvent>
            ) => {
              event.preventDefault();
              alert(`Ова покажува дека имаме информации за линијата Во Живо.`);
            }}
          />
        </div>
        {/* <p className="activeBusCardDescriptionText mb-2">{item.description}</p> */}
        {item.desc && (
          <p className="activeBusCardDescriptionText mb-2">{item.desc}</p>
        )}
        <div className="d-flex">
          <p
            className="activeBusCardNextStopText nextStopconteiner"
            style={{ color: COLORS.mossGreen }}>
            Следна станица: {item.nextStop?.name} {formatTime(item?.timeToNextStop)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ActiveLineCard;
