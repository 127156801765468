import React from "react";
import Container from "../../Components/Container/Container";
import { Link } from "react-router-dom";
import { COLORS } from "../../Styles/GlobalStyles";

const ErrorPage = () => {
  return (
    <div style={{ marginTop: 90, minHeight: "65vh" }}>
      <Container>
        <div className="row">
          <div className="col-12 text-center" style={{ marginTop: 70 }}>
            <h1 className="mb-4">404 Not Found</h1>
            <h2 className="mb-4">Страната што ја избравте не постои.</h2>
            <Link
              to={"/"}
              className="navigateToLineScreenButton marginBottomButton text-white"
              style={{
                textDecoration: "none",
                backgroundColor: COLORS.mossGreen,
              }}>
              Вратете се на почетна!
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ErrorPage;
