import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext/AppContext";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import Container from "../../Components/Container/Container";
import Timeline from "../../Components/Timeline/Timeline";
import { COLORS } from "../../Styles/GlobalStyles";
import "../../Styles/BusLineDetailsStyles/BusLineDetails.css";
import OperatingHoursModal from "../../Components/OperatingHoursModal/OperatingHoursModal";
import { scrollToTop } from "../../Utils/UtilityFunctions";

const BusLineDetails: React.FC = () => {
  const { id } = useParams();

  const {
    fetchBusLineDetailsById,
    busLineDetails,
    resetBusLineDetails,
    fetchingOperatingHoursByLineID,
    operatingHoursForEachLine,
    resetOperatinghouts,
    openOperatinghoursModal,
    operatinhHoursModal,
  } = useContext(AppContext);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (id) {
      fetchBusLineDetailsById(id);
      fetchingOperatingHoursByLineID(id);
      scrollToTop();
      interval = setInterval(() => {
        fetchBusLineDetailsById(id);
        fetchingOperatingHoursByLineID(id);
      }, 60000);
    }

    return () => {
      resetBusLineDetails();
      resetOperatinghouts();
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [id]);

  if (!busLineDetails) {
    return <LoadingPage />;
  }

  if (!operatingHoursForEachLine) {
    return <LoadingPage />;
  }

  return (
    <div style={{ marginTop: "90px" }}>
      <div
        className="titleLineDetails"
        style={{
          backgroundColor: COLORS.busStationTitleBackgroundLight,
          marginTop: "110px",
        }}>
        <h1>
          Линија {busLineDetails.line}{" "}
          {busLineDetails.id.includes("T") && " - Транскоп"}
          {busLineDetails.id.includes("14") && "(7)"}
        </h1>

        {busLineDetails.desc && (
          <p className="mb-2 mt-0">{busLineDetails.desc}</p>
        )}

        <p className="m-0">{busLineDetails.description}</p>
        <button
          className="calendarButton"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault();
            openOperatinghoursModal();
          }}>
          <img
            className="calendarImage"
            src="/images/img/calendarDark.png"
            alt=""
          />
        </button>
      </div>

      <Container>
        <Timeline
          data={busLineDetails.allStops}
          nextStop={busLineDetails.nextStop}
          timeToNextStop={busLineDetails.timeToNextStop}
          id={busLineDetails.id}
        />
        {operatinhHoursModal && (
          <OperatingHoursModal
            id={busLineDetails.id}
            lineName={busLineDetails.line}
            monthlyOverrides={operatingHoursForEachLine.monthly_overrides}
            nationalHolidays={
              operatingHoursForEachLine.holidays[0].national_holidays
            }
            religiousHolidays={
              operatingHoursForEachLine.holidays[1]?.religious_holidays
            }
          />
        )}
      </Container>
    </div>
  );
};

export default BusLineDetails;
