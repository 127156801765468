import React, { useContext, useEffect, useState } from "react";
import "../../Styles/NavbarStyle/Navbar.css";
import { COLORS } from "../../Styles/GlobalStyles";
import { NavLink, useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { AppContext } from "../../Context/AppContext/AppContext";
import { scrollToTop } from "../../Utils/UtilityFunctions";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 992);

  const handleNavLinkClick = () => {
    scrollToTop();
    setIsOpen(false);
  };

  const handleFirebaseAnalytics = (text: string) => {
    logEvent(analytics, "navbar_button_click", {
      button_name: text,
    });
  };

  const { handleSearchBar, showHideSearchBar, searchValue, handleSearchValue } =
    useContext(AppContext);

  const { pathname } = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg"
        style={{ backgroundColor: COLORS.mossGreen }}>
        <NavLink
          to={"/"}
          style={{ textDecoration: "none" }}
          onClick={scrollToTop}>
          <div className="headerLogoStyle">
            <img
              src="/images/logo-final.png"
              alt=""
              className="headerLogo"
              // style={{ width: 55, height: 55 }}
            />
            <h5 className="m-0 h5HeaderLogo" style={{ color: "white" }}>
              Bitola City Bus
            </h5>
          </div>
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          aria-controls="navbarSupportedContent"
          aria-expanded={isOpen}
          aria-label="Toggle navigation">
          {/* <span className="navbar-toggler-icon"></span> */}
          <span className="text-white">Мени</span>
        </button>
        <Collapse in={isOpen}>
          <div
            className={`navbar-collapse ${isMobile && "customCollapse"}`}
            id="navbarSupportedContent"
            style={{
              backgroundColor: COLORS.mossGreen,
            }}>
            <ul className="navbar-nav m-auto">
              <li
                className={`nav-item ${
                  pathname === "/" ? "customActive" : ""
                }`}>
                <NavLink
                  to={"/"}
                  className="nav-link p-0"
                  style={{ color: "black" }}
                  onClick={() => {
                    handleNavLinkClick();
                    handleFirebaseAnalytics("Активни линии");
                  }}>
                  <p className="nav-link m-0">
                    Активни линии <span className="sr-only">(current)</span>
                  </p>
                </NavLink>
              </li>
              <li>
                <hr className="m-0" />
              </li>
              <li
                className={`nav-item ${
                  pathname === "/stops" || pathname.startsWith("/stop/")
                    ? "customActive"
                    : ""
                }`}>
                <NavLink
                  to={"/stops"}
                  className="nav-link p-0"
                  style={{ color: "black" }}
                  onClick={() => {
                    handleNavLinkClick();
                    handleFirebaseAnalytics("Постојки");
                  }}>
                  <p className="nav-link m-0">
                    Постојки <span className="sr-only">(current)</span>
                  </p>
                </NavLink>
              </li>
              <li>
                <hr className="m-0" />
              </li>
              <li
                className={`nav-item ${
                  pathname === "/lines" || pathname.startsWith("/line/")
                    ? "customActive"
                    : ""
                }`}>
                <NavLink
                  to={"/lines"}
                  className="nav-link p-0"
                  style={{ color: "black" }}
                  onClick={() => {
                    handleNavLinkClick();
                    handleFirebaseAnalytics("Линии");
                  }}>
                  <p className="nav-link m-0">Линии</p>
                </NavLink>
              </li>
              <li>
                <hr className="m-0" />
              </li>

              <li
                className={`nav-item ${
                  pathname === "/aboutUs" && "customActive"
                }`}>
                <NavLink
                  to={"/aboutUs"}
                  className="nav-link p-0"
                  style={{ color: "black" }}
                  onClick={() => {
                    handleNavLinkClick();
                    handleFirebaseAnalytics("За нас");
                  }}>
                  <p className="nav-link m-0">За нас</p>
                </NavLink>
              </li>
            </ul>
          </div>
        </Collapse>
        <button style={{width: "140px", opacity: 0, cursor: "default", display: isMobile ? "none" : "block"}}></button>
      </nav>
    </>
  );
};

export default Navbar;
