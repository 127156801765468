import React, { useContext, useEffect } from "react";
import { AppContext } from "../../Context/AppContext/AppContext";
import LineCard from "../../Components/LineCard/LineCard";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import Container from "../../Components/Container/Container";

const LinesPage = () => {
  const { busLines, getBusLines } = useContext(AppContext);

  useEffect(() => {
    getBusLines()
  }, [])
  
  if (!busLines || busLines.length < 1) {
    return <LoadingPage />;
  }

  const sortedLines = busLines.sort((a, b) => Number(a.id) - Number(b.id));

  return (
    <div style={{ marginTop: "90px" }}>
      <Container>
        {sortedLines.map((line, index) => (
          <LineCard key={`${line.id}-${index}`} {...line} />
        ))}
      </Container>
    </div>
  );
};

export default LinesPage;
